export const environments = {
    local: {
        ApiServiceUrl: 'https://l194um5a44.execute-api.us-east-1.amazonaws.com/qa',
        DECTermsAndConditions: 'https://scprod-cms.duke-energy.com/_/media/PDFs/our-company/Shared-Solar-TCs/202646-DEC-SC-TC.pdf',
        azure_client_id: '',
        azure_tenant: '',
        azure_redirect_uri:'',
        decomHome: 'https://sc10qa.duke-energy.com/home',
    },
    sbx: {
        ApiServiceUrl: 'https://9hqyms0w7l.execute-api.us-east-1.amazonaws.com/sbx',
        DECTermsAndConditions: 'https://scprod-cms.duke-energy.com/_/media/PDFs/our-company/Shared-Solar-TCs/202646-DEC-SC-TC.pdf',
        decomHome: 'https://sc10qa.duke-energy.com/home',
    },
    dev: {
        ApiServiceUrl: 'https://64esrlzf1h.execute-api.us-east-1.amazonaws.com/dev',
        DECTermsAndConditions: 'https://scprod-cms.duke-energy.com/_/media/PDFs/our-company/Shared-Solar-TCs/202646-DEC-SC-TC.pdf',
        decomHome: 'https://sc10qa.duke-energy.com/home',
    },
    qa: {
        ApiServiceUrl: 'https://l194um5a44.execute-api.us-east-1.amazonaws.com/qa',
        DECTermsAndConditions: 'https://scprod-cms.duke-energy.com/_/media/PDFs/our-company/Shared-Solar-TCs/202646-DEC-SC-TC.pdf',
        decomHome: 'https://sc10qa.duke-energy.com/home',
    },
    prod: {
        ApiServiceUrl: 'https://ooaslo1qj8.execute-api.us-east-1.amazonaws.com/prod',
        DECTermsAndConditions: 'https://scprod-cms.duke-energy.com/_/media/PDFs/our-company/Shared-Solar-TCs/202646-DEC-SC-TC.pdf',
    },
};