import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ControlOption, DeSnackbarService } from '@de-electron/electron-angular-components';
import { BehaviorSubject, from, of, pipe } from "rxjs"
import { AccountService, ApiService, AuthenticationService } from 'src/app/services';

import _ from 'lodash';

import { CustomerDetail } from 'src/app/modules/enrollment/models/data/account-detail.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CancelEnrollmentDialogComponent } from '../cancel-enrollment-dialog/cancel-enrollment-dialog.component';
import { AllocationStatus } from 'src/app/modules/shared/models/enum.model';
import { MatSelectChange } from '@angular/material/select';
import {MatTableModule, MatTableDataSource} from '@angular/material/table';
import { catchError, finalize, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';
import { DatePipe, CurrencyPipe, DecimalPipe, formatDate } from '@angular/common';
import { WindowReferenceService } from 'src/app/services/window-reference.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  // Chart Options

  // view: any[];
  width: number = 1200;
  height: number = 300;
  fitContainer: boolean = true;

    view: any[] = [this.width, this.height];
  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Billing Month (Rolling 12 Months)';
  showYAxisLabel = true;
  yAxisLabel = 'kWh';
  timeline = true;
  doughnut = true;
  colorScheme = {
    domain: ['#00853f', '#387ea3']
  };
  //pie
  showLabels = true;

  // Default settings selected account had to dictate whether save button is disable
  public billImpactData: any;
  public historicalBillingData: any;
  public historicalBillingChartData: any;
  public histBillingItemsDataSource: MatTableDataSource<any>;
  public accountDetail = null;
  public loggedIn;
  public showBanner = true;
  public accountOptions = [];
  public disableSelect = true;
  public selectedAccount: any;
  public columnsToDisplay = ['bill_interval', 'solar_generation_kwh', 'credits', 'fees', 'savings'];
  public billingTabOptions: ControlOption[] = [
    {
      value: 'kWh',
      viewValue: 'kWh'
    },
    {
      value: 'dollars',
      viewValue: 'Dollars'
    }
  ];

  public solarProdTimeTabOptions: ControlOption[] = [
    {
      value: 'last12Months',
      viewValue: 'Last 12 Months'
    },
    {
      value: 'lifetime',
      viewValue: 'Lifetime'
    }
  ];

  public allAvaliableAlerts: any = [];
  public alerts: any = [];
  public numOfItems = 0;

  // Display mode for solar impact widget
  public impactDisplayMode: string = 'kWh';

  // Metrics for Edit Sub widget
  public currentAllocation: number = null;
  public remainingAllocation: number = null;
  public cancelEnrollmentDialogRef: MatDialogRef<CancelEnrollmentDialogComponent>;

  public impactDataLoading$ = new BehaviorSubject<boolean>(true);
  public billingHistoryLoading$ = new BehaviorSubject<boolean>(true);
  public isMobile = true;
  public hasWaitlistAllocation = false;
  public allAlerts: any = [];
  public TESTACCOUNTNUMBER = '910041474235';
  public accountNumber = this.accountService.getSelectedAccountNumber();

  public bill_interval: any;
  public generation: any;

  public customerSegments: any;
  public customerSegment: [];

  public hasAlertExpireDateElapsed: boolean;
  public showDecomBanner = false;

  constructor(
    public router: Router,
    public apiService: ApiService,
    public authService: AuthenticationService,
    public accountService: AccountService,
    private snackbar: DeSnackbarService,
    private windowRef: WindowReferenceService,

    public dialog: MatDialog) {
    this.getAlerts();
    if(environment.EnvName == "sbx" || environment.EnvName == "local"){
     this.accountNumber = this.TESTACCOUNTNUMBER;
    }
    this.apiService.getSolarSubscriptionImpact(this.accountNumber).toPromise().then(res => {
      this.billImpactData = res;
      this.impactDataLoading$.next(false);
    });
    // historical billing api call
    this.apiService.getHistoricalBilling(this.accountNumber, "chart").toPromise().then(res => {
      this.historicalBillingChartData = res;
      this.impactDataLoading$.next(false);
    });

    // Customer Segment Alert Message api call
    this.apiService.getCustomerSegments().toPromise().then(res => {
      this.customerSegments = res;
      this.impactDataLoading$.next(false);
      const customerSeg = this.customerSegments.filter((custSegs) => {
        return custSegs.customer_segment_id == this.selectedAccount.customer_segment.customer_segment_id;
      })
      
      // this.customerSegment.push(customerSeg)
      this.customerSegment = customerSeg;
      this.hasAlertExpireDateElapsed = this.hasAlertExpireDatePassed(new Date(customerSeg[0].customer_segment_alert_expiry_date))
    });
    // this.accountService.enrollmentHasBeenCanceled$.subscribe(canceled => {

    // })
    this.accountService.selectedAccountNumber$.subscribe(selectedAccountNumber => {
      if (!selectedAccountNumber) {
        return;
      }
      this.getAlerts();
      // im moving this to ngOnInit
      // this.impactDataLoading$.next(true);
      this.billingHistoryLoading$.next(true);
      if (selectedAccountNumber !== null) {
        this.apiService.getSolarSubscriptionImpact(this.accountNumber).toPromise().then(res => {
          this.billImpactData = res;
          this.impactDataLoading$.next(false);
        });
        this.calculateAllocations(this.accountService.getSelectedAccount());
      }
    });
  }

  ngOnInit(): void {
    this.isMobile =  window.innerWidth < 601;
    this.showDecomBanner = JSON.parse(localStorage.getItem('cecAuth'));
    // In customer portal, selectedAccounts array should always have just one account
    this.impactDataLoading$.next(true);
    const selectedAccountNumber = this.accountService.getSelectedAccountNumber();
    if (!selectedAccountNumber) {
      // TODO should throw some error or something here
      return;
    }
    this.getAlerts();
    this.accountDetail = this.accountService.getSelectedAccount();
    this.accountService.getMessages(this.accountDetail.account_number).subscribe(x => {
    });

    // console.log("total sub kw: " + this.accountDetail.max_subscription_size_kw)

    this.getData();

    this.billingHistoryLoading$.next(false);

    this.calculateAllocations(this.accountDetail);
    this.loggedIn = this.authService.loggedIn.getValue();
    if (!this.loggedIn) {
    } else {
      this.setAccounts();
    }
    this.pushPageLoadGA();
  }

  public onSelect(event:any) {

  }

  public closeBanner(): void {
    this.showBanner = false;
  }

  getData() {
    this.histBillingItemsDataSource = new MatTableDataSource(null);

    this.apiService
      .getHistoricalBilling(this.accountDetail.account_number, "table")
      .pipe(
        take(1),
        map((response) => {
          if (!response) {
            return this.handleHistoricalBillingError(
              'Historical Billing data response is blank'
            );
          }
          this.histBillingItemsDataSource = new MatTableDataSource(response);
        }),
        catchError((error) => this.handleHistoricalBillingError(error)),
        finalize(() => this.impactDataLoading$.next(false))
      ).subscribe();
    
  }

  convertBillingDate(element: any) {
    let converted_date = new Date(element.bill_year, element.bill_month, (element.bill_date).substring(7,9));
    return converted_date
  }

  currencyPipe = new CurrencyPipe('en-US');

  decimalPipe = new DecimalPipe('en-US');

  /* Export Historical Billing as XLSX */
  exportExcel() {
    const exportAllocationList = this.histBillingItemsDataSource.filteredData.map(rec => {
      return {
        'Bill Date': this.convertBillingDate(rec),
        'Subscription Based Generation (kWh)': this.decimalPipe.transform(rec.cec_credit_kwh),
        'Credits Earned': this.currencyPipe.transform(rec.cec_credit || '0'),
        'Subscription Fees': this.currencyPipe.transform(rec.cec_fee || '0'),
        'Net Cost (-) / Savings (+)': this.currencyPipe.transform(rec.cec_credit - rec.cec_fee),
      };
    });
    this.exportExcelSpreadsheet(exportAllocationList,
      'Billing Export ' + this.accountNumber + ' ' + this.getDate(),
      'BillingExport' + this.getDate() + '.xlsx');
  }

  exportExcelSpreadsheet(exportData: any, exportSheetName: string, exportFileName: string) {
    const workSheet = XLSX.utils.json_to_sheet(exportData);
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, exportSheetName);
    XLSX.writeFile(workBook, exportFileName);
  }

  // pipe = new DatePipe('en-US');

  getDate() {    
    return formatDate(new Date(), 'yyyy-MM-dd', 'en');
  }

  pushPageLoadGA() {
    this.windowRef.nativeWindow.dataLayer.push({'event':'send-VPV', 'vpv-name':'/vpv/cec/solar-subscription/solar-subscription-dashboard/load'});
  }

  hasAlertExpireDatePassed(expiry) {
    const alertExpireDate = new Date(expiry);
    const currentDate = new Date();

    this.hasAlertExpireDateElapsed = alertExpireDate < currentDate;

    return alertExpireDate < currentDate;
  }
  

  hasYearPassed(activeDate, n) {
    // Create new Date from activeDate string param
    const realDate = new Date(activeDate.substring(0,10))
  
    // Get the current date and time
    const currentDate = new Date();
  
    // Calculate the date n months ago
    const nMonthsAgo = new Date();
    nMonthsAgo.setMonth(currentDate.getMonth() - n);
  
    // Compare the active date with the date n months ago
    return !(realDate >= nMonthsAgo && realDate <= currentDate);
  }

  
  getAlerts() {
    this.accountService.getMessages(this.accountNumber).subscribe(al => {
        this.allAlerts = al;
        // this.allAlerts = [{created_at: '5-1-2023',alert_type: 'Enrollment', alert_message: 'Message Here' }]
        this.allAvaliableAlerts = this.allAlerts;
        this.alerts = this.allAlerts.slice(0, 5);
        this.numOfItems = this.alerts.length;
    } );
}

onAlertSelection(created_date, alert_type, alert_message) {

}


  handleSolarSubscriptionImpactError(error: any): any {
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category':'cec_error', 
        'event-action': `Error in impact data request`, 
        'event-label':'solar-subscription-error', 'event':'event-click' });
    console.log(`error in impact data request`, error);
  }

  handleHistoricalBillingError(error: any): any {
    this.windowRef.nativeWindow.dataLayer.push({ 
        'event-category':'cec_error', 
        'event-action': `Error in historical billing data request`, 
        'event-label':'historical-billing-error', 'event':'event-click' });
    console.log(`error in historical billing data request`, error);
  }

  navigateTo(route: string) {
    if(route.indexOf('document') > 0) {
      this.pushViewDocGA();
    }
    this.router.navigate([route]);
  }

  pushViewDocGA(){
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category':'solar-subscription_dashboard', 
        'event-action':'view-documents',
        'event-label':'view-documents',
        'event':'event-click'
      });
  }

  onEditSubscription(index) {
    this.pushEditOrCancelGA('edit');
    this.accountService.setSelectedAllocation(this.accountDetail.allocations[index]);
    if (this.authService.getAdminLoggedIn()) {
      this.navigateTo('/admin/edit-subscription');
    }
    else {
    this.navigateTo('/customer-portal/edit-subscription')
    }
  }

  onViewDocuments() {
    if (this.authService.getAdminLoggedIn()) {
      this.navigateTo('/admin/documents');
    }
    else {
      this.navigateTo('/customer-portal/documents')
    }
  }

  pushEditOrCancelGA(selection){
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category':'solar-subscription_dashboard', 
        'event-action': selection,
        'event-label':'your-subscriptions',
        'event':'event-click'
      });
  }

  onCancelSubscription(index) {
    this.pushEditOrCancelGA('cancel');
    let allocation = this.accountDetail.allocations[index];
    let account = this.accountDetail;
      this.cancelEnrollmentDialogRef = this.dialog.open(CancelEnrollmentDialogComponent, {
        id: 'cancelEnrollmentDialog',
        minWidth: '60vw',
        minHeight: '60vh',
        data: {
          allocation,
          account
        }
      });
  }

  onSelectionChange(selection: string) {
    this.pushSelectionChangeGA(selection);
    this.impactDisplayMode = selection;
  }

  pushSelectionChangeGA(selection){
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category':'your-solar-subscription-impact', 
        'event-action':selection,
        'event-label':'subscription-options-|-enrollment',
        'event':'event-click'
      });
  }

  dateToShow(allocation: any) {
    const dateToReturn = allocation.cancelled_date !== null ?
        allocation.cancelled_date : allocation.active_date !== null ?
        allocation.active_date : allocation.pending_date !== null ?
        allocation.pending_date : allocation.created_at
    return dateToReturn
  }

  nextIncreaseDateToShow(allocation: any) {
    const dateToReturn =  new Date(allocation.active_date);
    dateToReturn.setFullYear(dateToReturn.getFullYear() + 1);
    return dateToReturn
  }

  calculateAllocations(selectedAccount: CustomerDetail) {
    // Reset allocation amounts
    this.currentAllocation = 0;
    this.remainingAllocation = 0;
    const nonCancelledAllocations = _.filter(selectedAccount.allocations, (allocation) => {
      return allocation.allocation_status_id !== AllocationStatus.Cancelled && allocation.allocation_status_id !== AllocationStatus.VerificationFailed;
    });
    nonCancelledAllocations.forEach(allocation => {
      if(allocation.allocation_status_id === AllocationStatus.Waitlist) {
        this.hasWaitlistAllocation = true;
      }
      this.currentAllocation += allocation.allocation_size_kw;
    });
    // TODO: Calculate this from the backend in an available_size_kw field: DTCEC-1705
    this.remainingAllocation = selectedAccount.max_subscription_size_kw - this.currentAllocation;
  }

  setAccounts() {
    this.accountOptions = this.accountService.getAccounts();
    
    if (this.accountOptions !== null) {
      this.disableSelect = false;
      this.selectedAccount = this.accountOptions.find(acct => acct.allocations.find(allo => allo.allocation_status_description === 'Enrolled'));

      if (!this.selectedAccount && this.accountOptions.length > 0) {
        this.selectedAccount = this.accountOptions[0];
        this.navigateTo('/enrollment/verified-accounts');
      }
      this.accountService.setCustomerSegmentId(this.selectedAccount.customer_segment.customer_segment_id);
      this.accountService.setSelectedAccountNumber(this.selectedAccount.account_number);
    } else {
      this.navigateTo('/enrollment');
    }
  }

  onAccountSelectionChange(event: MatSelectChange) {
    // No active enrollments for selected account, redirect to enrollment piece
    this.pushChangeAccountGA();
    if (event.value.allocations.filter(allo => allo.allocation_status_id ===  AllocationStatus.Enrolled).length === 0) {
      this.navigateTo('/enrollment/verified-accounts');
    } else {
      this.selectedAccount = event.value;
      this.accountService.setSelectedAccountNumber(this.selectedAccount.account_number);
    }
  }

  pushChangeAccountGA(){
    this.windowRef.nativeWindow.dataLayer.push({
        'event-category':'solar-subscription_dashboard',
        'event-action':'change-account',
        'event-label':'account-selector',
        'event':'event-click'
      });
  }

  public returnToMyAcc(){
    this.windowRef.nativeWindow.dataLayer.push({
      'event':'send-VPV', 
      'vpv-name':'/vpv/cec/market-rate/login/return-to-my-account-dashboard/clickthrough'}
    );
    this.windowRef.nativeWindow.dataLayer.push({ 
      'event-category': 'cec_sos', 
      'event-action': '/login',
      'event-label': 'return-to-my-account-|-clickthrough',
      'event': 'event-click' 
    });
    const authToken = JSON.parse(localStorage.getItem('deAuthToken'));
    window.location.href = 
    `https://uat.apigee.duke-energy.app/v1/cust/digitalservices/public/token/authorize?scope=EXPERIENCE_SELECTOR&state=Login&response_type=code&redirect_uri=https%3A%2F%2Fuat.apigee.duke-energy.app%2Fv1%2Fcust%2Fdigitalservices%2Fpublic%2Fwasp%2Fredirect&access_token=${authToken}`;
  }

}

const SAMPLE_DATA: Object[] = [{
  id: 1,
  bill_interval: Date(),
  generationDays: '28',
  solar_generation_kwh: '351 kWh',
  creditsEarned: '$8.66',
  subscriptionFees: '$8.66',
  netCostSavings: '-$5.60'    
}];
